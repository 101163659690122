import { gql, request } from 'graphql-request'

export const EDIT_PROFILE =gql`
mutation PROFILEDATA($p:InputAdresse,$p1:InputAnsprechpartner){
    profile(p:$p,p1:$p1){
        ADR{
            ADR28
            ADR2030
            ADR11010
            ADR218746
            ADR139060
            ADR14503
            ADR217011
            ADR223340
            ADR29614
            #ADR233220
        }
        ANP{
            ANP18
            ANP98
            ANP49711
            ANP49991
            ANP498316
            ANP121060
            ANP450132
            ANP167530
            ANP170540
            ANP106030
            ANP9140
            ANP13140
            ANP112020
            ANP49701
        }
    }
}
`

var profile={
    editprofile:(anp, adr)=>{
        request("/q",EDIT_PROFILE,{p:adr, p1:anp}).then((data)=>{
            if(data) {
                //console.log(data);
            }
        });
    }

}
export default profile;
